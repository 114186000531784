import Slider from "react-slick";
import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdStarRate } from "react-icons/md";
import DataTable from "react-data-table-component";
import { GiTrophyCup } from "react-icons/gi";
import graph1 from "../../../assets/Group 6.png";
import graph2 from "../../../assets/Group 4.png";
import graph3 from "../../../assets/Group 7.png";
import gifts from "../../../assets/3d render blue gift box with gold ribbon package.png";
import { useNavigate } from "react-router-dom";
import image from "../../../assets/opportunitiesImage copy.png";
import ProgressBar from "@ramonak/react-progress-bar";

const Opportunities = () => {
  const [] = useState({
    NetWorth: { value: 5300000, percentage: 121 },
    todaysGain: { value: 2173, percentage: 0.05 },
    totalProfitsEarned: { value: 954000, percentage: 18 },
    capitalInvested: { value: 4356000, percentage: 100 },
  });
  const nav = useNavigate();
  const months = ["Next Month", "Next Day", "Next Year"];

  const [selectedMonth, setSelectedMonth] = useState("");

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: false,
    responsive: [
      {
        breakpoint: 1164,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  const [activeTab, setActiveTab] = useState("DESCRIPTION");

  const renderContent = () => {
    switch (activeTab) {
      case "DESCRIPTION":
        return (
          <div className="text-black text-sm mb-4 text-justify">
            <p>
              <b>Description</b>
            </p>
            <p>
              Commercial warehouse located in the prime location of
              Sriperumbudur. Waiting to be fully funded.
            </p>
          </div>
        );
      case "NEAR BY":
        return (
          <div>
            <div className="text-black text-sm mb-4  text-justify">
              <p>
                <b>Near by</b>
              </p>
              <p>
                Commercial warehouse located in the prime location of
                Sriperumbudur. Waiting to be fully funded.
              </p>
            </div>
          </div>
        );
      case "MAP":
        return (
          <div>
            <div className="text-black text-sm mb-4  text-justify">
              <p>
                <b>Tenants</b>
              </p>
              <p>
                Commercial warehouse located in the prime location of
                Sriperumbudur. Waiting to be fully funded.
              </p>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const [activTab2, setActivTab2] = useState("DESCRIPTION");

  const renderContent2 = () => {
    switch (activTab2) {
      case "DESCRIPTION":
        return (
          <div className="text-black text-sm mb-4 text-justify">
            <p>
              <b>Description</b>
            </p>
            <p>
              Commercial warehouse located in the prime location of
              Sriperumbudur. Waiting to be fully funded.
            </p>
          </div>
        );
      case "NEAR BY":
        return (
          <div>
            <div className="text-black text-sm mb-4  text-justify">
              <p>
                <b>Near by</b>
              </p>
              <p>
                Commercial warehouse located in the prime location of
                Sriperumbudur. Waiting to be fully funded.
              </p>
            </div>
          </div>
        );
      case "MAP":
        return (
          <div>
            <div className="text-black text-sm mb-4  text-justify">
              <p>
                <b>Map</b>
              </p>
              <p>
                Commercial warehouse located in the prime location of
                Sriperumbudur. Waiting to be fully funded.
              </p>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  //for Datatable

  const datas = [
    {
      ProjectName: "Moorthy Infra",
      PAReturns: "24% PA",
      Tenure: "48 months",
      FullyFunded: " 4.5 Cr",
    },
    {
      ProjectName: "Lloyd RE",
      PAReturns: "36% PA",
      Tenure: "36 months",
      FullyFunded: "2.5 Cr",
    },
    {
      ProjectName: "Tata P1",
      PAReturns: "18% PA",
      Tenure: "18 months",
      FullyFunded: "9.5 Cr",
    },
    {
      ProjectName: "Jindal Steel",
      PAReturns: "14% PA",
      Tenure: "12 months",
      FullyFunded: "5.5 Cr",
    },
  ];

  // Add more data as needed

  const columns = [
    {
      name: "Project Name",
      selector: (row) => row.ProjectName,
      style: {
        fontWeight: "bold",
      },
    },
    {
      name: "PA Returns",
      selector: (row) => row.PAReturns,
    },
    {
      name: "Tenure",
      selector: (row) => row.Tenure,
    },
    {
      name: "Fully Funded",
      selector: (row) => row.FullyFunded,

      cell: (row) => (
        <>
          <GiTrophyCup className="size-8 text-yellow-400 " />
          <span className="ml-2">{row.FullyFunded}</span>
        </>
      ),
    },
  ];

  const customStyles = {
    Headers: {
      style: {},
    },
    headRow: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
    rows: {
      style: {
        borderRadius: "10px",
        boxShadow: "0 3px #D3D3D3",
        backgroundColor: "#F5F5F5",
        marginBottom: "10px",
      },
    },
    cells: {
      style: {},
    },
  };

  const [showActiveContent, setShowActiveContent] = useState(true);

  const toggleContent = (tab) => {
    setShowActiveContent(tab === "Active Projects");
  };

  return (
    <div className="flex flex-col bg-gray-100">
      <div className="lg:pl-8 mt-10  sm:space-x-10 space-x-5 text-xl lg:text-start text-center">
        <button
          onClick={() => toggleContent("Active Projects")}
          className={`focus:outline-none font-bold ${
            showActiveContent
              ? "text-[#2272FF]  border-b-2 border-[#2272FF]"
              : ""
          }`}
        >
          Active Projects
        </button>
        <button
          onClick={() => toggleContent("Completed Projects")}
          className={`focus:outline-none font-bold  ${
            !showActiveContent
              ? "text-[#2272FF] border-b-2 border-[#2272FF]"
              : ""
          }`}
        >
          Completed Projects
        </button>
      </div>
      <div className="flex lg:flex-row flex-col w-full justify-between ">
        <div className="lg:w-3/5 w-full mx-auto p-5 ">
          {showActiveContent ? (
            <div className="content w-full mx-auto -mt-6">
              <section className="w-full drop-shadow-xl mx-auto">
                <div className="flex items-center justify-center mt-6 ">
                  <div className="w-full" data-aos="fade-up">
                    <Slider {...settings} className="">
                      {/* Slide 1 */}
                      <div className="flex flex-col items-center justify-center p-2">
                        <div className="pb-5 lg:flex z-20  md:mb-[-35px] mb-[-30px]  md:block  justify-end items-end space-x-5 flex flex-row">
                          <button className="text-black bg-[#00FF00] z-10 font-bold text-xs rounded-full md:px-5 px-2 py-2">
                            Capital Protected
                          </button>
                          <button className="text-black bg-[#00FF00] z-10 font-bold text-xs rounded-full px-5  py-2">
                            Trending
                          </button>
                        </div>
                        <div className="font-inter">
                          <div className="bg-[#D6FFDD]  w-full rounded-[18px]">
                            <div className="-z-10 rounded-2xl">
                              <img src={image} alt="" className="rounded-2xl" />
                            </div>
                            <p className="text-green-600 px-4 text-xs font-bold pt-8 md:mb-0">
                              Real Estate
                            </p>
                            <div className="md:ml-0 px-4">
                              <h1 className="text-black text-md md:text-1xl font-bold py-1">
                                CRE-II
                              </h1>
                              <p className="text-black text-sm mb-4 font-semibold">
                                Mappedu
                              </p>
                              <div className="flex items-center gap-2">
                                <ProgressBar
                                  completed={1.2}
                                  maxCompleted={10}
                                  barContainerClassName="container"
                                  completedClassName="barCompleted"
                                  labelClassName="label"
                                  className="w-2/5"
                                />
                                <p className="text-sm">₹ 1.2 Crore</p>
                              </div>
                              <br />
                              <div>
                                <div className="flex absolute -m-4 ml-0 flex-row md:mr-3 mb-2">
                                  <button
                                    className={`text-xs font-semibold text-black ${
                                      activeTab === "DESCRIPTION"
                                        ? "border-b-2  border-black"
                                        : ""
                                    }`}
                                    onClick={() => setActiveTab("DESCRIPTION")}
                                  >
                                    DESCRIPTION
                                  </button>
                                  <button
                                    className={`px-5 font-semibold text-xs text-black ml-1 ${
                                      activeTab === "NEAR BY"
                                        ? "border-b-2 border-black"
                                        : ""
                                    }`}
                                    onClick={() => setActiveTab("NEAR BY")}
                                  >
                                    NEAR BY
                                  </button>
                                  <button
                                    className={`px-2 text-xs font-semibold text-black ml-1 ${
                                      activeTab === "MAP"
                                        ? "border-b-2 border-black"
                                        : ""
                                    }`}
                                    onClick={() => setActiveTab("MAP")}
                                  >
                                    TENANTS
                                  </button>
                                </div>
                                <hr className="top-1 lg:mb-3 text-gray" />
                                {renderContent()}
                              </div>
                              <div className="flex font-sans flex-row mb-0 text-black gap-2 font-medium">
                                <p className="px-1 text-xs">ROI %</p>
                                <p className="pl-6 text-xs">TENURE</p>
                                <p className="pl-2 text-xs">MIN INVESTING</p>
                              </div>
                              <div className="flex gap-4 mt-2">
                                <button className="bg-gray rounded-md px-2 py-1 text-xs text-[#00ff00] bg-black">
                                  18% PA
                                </button>
                                <button className="bg-gray rounded-md px-3 py-1 text-xs text-[#00ff00]  bg-black ">
                                  4 Yr
                                </button>
                                <button className="bg-gray rounded-md px-3 py-1 text-xs text-[#00ff00]   bg-black">
                                  1 Lakh
                                </button>
                              </div>
                              <br />
                              <div className="pb-5 space-y-3">
                                <button
                                  onClick={() =>
                                    nav("/homepage/opportunities/Learnmore/investnow")
                                  }
                                  className="border hover:bg-[#00ff00] duration-500 border-[#00FF00] text-black font-bold text-xs py-1 rounded-lg w-full px-20"
                                >
                                  INVEST NOW
                                </button>
                                <br />
                                <button
                                  onClick={() =>
                                    nav("/homepage/opportunities/Learnmore")
                                  }
                                  className="bg-[#00FF00] hover:drop-shadow-2xl duration-500  text-black font-bold w-full rounded-lg text-xs py-1"
                                >
                                  LEARN MORE
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Slide 2 */}

                      <div className="flex flex-col items-center justify-center p-2">
                        <div className="pb-5 lg:flex z-20  md:mb-[-35px] mb-[-30px]  md:block  justify-end items-end space-x-5 flex flex-row">
                          <button className="text-black bg-[#00FF00] z-10 font-bold text-xs rounded-full md:px-5 px-2 py-2">
                            Capital Protected
                          </button>
                          <button className="text-black bg-[#00FF00] z-10 font-bold text-xs rounded-full px-5  py-2">
                            Trending
                          </button>
                        </div>
                        <div className="font-inter">
                          <div className="bg-[#D6FFDD]  w-full rounded-[18px]">
                            <div className="-z-10 rounded-2xl">
                              <img src={image} alt="" className="rounded-2xl" />
                            </div>
                            <p className="text-green-600 px-4 text-xs font-bold pt-8 md:mb-0">
                              Real Estate
                            </p>
                            <div className="md:ml-0 px-4">
                              <h1 className="text-black text-md md:text-1xl font-bold py-1">
                                CRE-II
                              </h1>
                              <p className="text-black text-sm mb-4 font-semibold">
                                Mappedu
                              </p>
                              <div className="flex items-center gap-2">
                                <ProgressBar
                                  completed={1.2}
                                  maxCompleted={10}
                                  barContainerClassName="container"
                                  completedClassName="barCompleted"
                                  labelClassName="label"
                                  className="w-2/5"
                                />
                                <p className="text-sm">₹ 1.2 Crore</p>
                              </div>
                              <br />
                              <div>
                                <div className="flex absolute -m-4 ml-0 flex-row md:mr-3 mb-2">
                                  <button
                                    className={`text-xs font-semibold text-black ${
                                      activeTab === "DESCRIPTION"
                                        ? "border-b-2  border-black"
                                        : ""
                                    }`}
                                    onClick={() => setActiveTab("DESCRIPTION")}
                                  >
                                    DESCRIPTION
                                  </button>
                                  <button
                                    className={`px-5 font-semibold text-xs text-black ml-1 ${
                                      activeTab === "NEAR BY"
                                        ? "border-b-2 border-black"
                                        : ""
                                    }`}
                                    onClick={() => setActiveTab("NEAR BY")}
                                  >
                                    NEAR BY
                                  </button>
                                  <button
                                    className={`px-2 text-xs font-semibold text-black ml-1 ${
                                      activeTab === "MAP"
                                        ? "border-b-2 border-black"
                                        : ""
                                    }`}
                                    onClick={() => setActiveTab("MAP")}
                                  >
                                    TENANTS
                                  </button>
                                </div>
                                <hr className="top-1 lg:mb-3 text-gray" />
                                {renderContent()}
                              </div>
                              <div className="flex font-sans flex-row mb-0 text-black gap-2 font-medium">
                                <p className="px-1 text-xs">ROI %</p>
                                <p className="pl-6 text-xs">TENURE</p>
                                <p className="pl-2 text-xs">MIN INVESTING</p>
                              </div>
                              <div className="flex gap-4 mt-2">
                                <button className="bg-gray rounded-md px-2 py-1 text-xs text-[#00ff00] bg-black">
                                  18% PA
                                </button>
                                <button className="bg-gray rounded-md px-3 py-1 text-xs text-[#00ff00]  bg-black ">
                                  4 Yr
                                </button>
                                <button className="bg-gray rounded-md px-3 py-1 text-xs text-[#00ff00]   bg-black">
                                  1 Lakh
                                </button>
                              </div>
                              <br />
                              <div className="pb-5 space-y-3">
                                <button
                                  onClick={() =>
                                    nav("/homepage/opportunities/Learnmore/investnow")
                                  }
                                  className="border hover:bg-[#00ff00] duration-500 border-[#00FF00] text-black font-bold text-xs py-1 rounded-lg w-full px-20"
                                >
                                  INVEST NOW
                                </button>
                                <br />
                                <button
                                  onClick={() =>
                                    nav("/homepage/opportunities/Learnmore")
                                  }
                                  className="bg-[#00FF00]  text-black font-bold w-full hover:drop-shadow-2xl duration-500 rounded-lg text-xs py-1"
                                >
                                  LEARN MORE
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Add more slides with content as needed */}
                    </Slider>
                  </div>
                </div>
              </section>
            </div>
          ) : (
            <div className="content w-full mx-auto -mt-4">
              <section className="w-full drop-shadow-xl mx-auto bg-white rounded-xl">
                <div className="flex items-center justify-center mt-6 bg-white rounded-xl">
                  <div className="w-full px-5 py-3" data-aos="fade-up">
                    <DataTable
                      columns={columns}
                      data={datas}
                      customStyles={customStyles}
                    />
                  </div>
                </div>
              </section>
            </div>
          )}
        </div>

        {/* <div className="sticky top-0 lg:w-2/5 w-full mx-auto">
          <div className="bg-[#303030] mt-6 rounded-3xl mb-5  mx-auto xs:w-[350px] w-full lg:h-[574px] md:text-center p-4 border border-[#E5E5E5]">
            <div className="flex flex-row gap-16 lg:flex-row lg:items-center lg:justify-between  lg:gap-20">
              <p className="font-bold text-xs text-white">
                New Project Coming Soon
              </p>

              <select
                id="monthDropdown"
                onChange={handleMonthChange}
                value={selectedMonth}
                className="text-xxs sm:text-xs bg-[#303030] rounded-md py-0.5 sm:py-1 border text-gray-300 border-white"
              >
                <option value="" disabled>
                  Next Week
                </option>
                {months.map((month, index) => (
                  <option key={index} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col lg:flex-row lg:gap-3 lg:items-center items-center lg:justify-center">
              <div className="mt-5 lg:mt-0">
                <img src={graph1} className="mb-3 mt-5"></img>
                <img src={graph2}></img>
              </div>
              <img src={graph3} className="mt-3 w-[200px] lg:ml-3"></img>
            </div>

            <hr className="text-gray-400 mt-10" />

            <div className="ml-3 text-left">
              <p className="font-semibold mt-6   text-white">
                *Warehouse VII Expected
              </p>
              <p className="text-xs text-gray-400 mt-2 font-semibold">
                Last updated 1 Week Ago
              </p>
              <button className="text-white py-2 mt-3 px-3 text-xs bg-gray-500 rounded-md">
                Stay Tuned For Updates
              </button>
            </div>
            <img
              src={gifts}
              alt=""
              className="h-[140px] mx-auto my-auto mt-5"
            />
            <p className="text-xs text-gray-400 mt-2 font-semibold text-center">
              *Pre-Investing Window To Open Soon!
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Opportunities;
