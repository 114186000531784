import React, { useState, useRef } from "react";
import { Outlet } from "react-router-dom";
import Image1 from "../../../assets/Group 3 (1).png";
import { BsArrowRightSquare, BiSolidChevronUpSquare } from "react-icons/bs";
import { FaAmazon } from "react-icons/fa";
import {
  BiDownArrow,
  BiSolidChevronDownSquare,
  BiUpArrow,
} from "react-icons/bi";
import { SiCocacola } from "react-icons/si";
import { ImWindows } from "react-icons/im";
import { BsDownload } from "react-icons/bs";
import { SiBmw } from "react-icons/si";
import { TbPointFilled } from "react-icons/tb";
import { IoIosArrowRoundForward } from "react-icons/io";
// import { useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DataTable from "react-data-table-component";
import { GiTrophyCup } from "react-icons/gi";
import Img from "../../../assets/progress.png";

export default function Snapshot() {
  const data = [
    { name: "Warehouse IV LLP", amount: "₹1,00,000", percentage: "10%" },
    { name: "Machinery Leasing", amount: "₹5,00,000", percentage: "5%" },
    { name: "Machinery Leasing", amount: "₹5,00,000", percentage: "5%" },
    { name: "Machinery Leasing", amount: "₹5,00,000", percentage: "5%" },
  ];

  const [showAll, setShowAll] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const containerRef = useRef(null);

  const displayedData = showAll || scrolled ? data : data.slice(0, 2);

  const handleScroll = () => {
    setScrolled(true);
  };

  const handleToggleShowAll = () => {
    if (showAll) {
      containerRef.current.scrollTop = 0; // Scroll to the top
    } else {
      containerRef.current.scrollTop = containerRef.current.scrollHeight; // Scroll to the bottom
    }
    setShowAll((prev) => !prev);
    setScrolled(false);
  };

  //for Datatable

  const datas = [
    {
      ProjectName: "You",
      TotalFunds: "4 Crores",
      Position: "9.5 Cr",
    },
    {
      ProjectName: "Baskaran",
      TotalFunds: "2 Crores",
      Position: "2nd Rank",
    },
    {
      ProjectName: "Raja Babu",
      TotalFunds: "1.5 Crores",
      Position: "9.5 Cr",
    },
    {
      ProjectName: "Arun Kumar",
      TotalFunds: "89 Lakhs",
      Position: "9.5 Cr",
    },
    {
      ProjectName: "Arun Kumar",
      TotalFunds: "89 Lakhs",
      Position: "9.5 Cr",
    },
    {
      ProjectName: "Arun Kumar",
      TotalFunds: "89 Lakhs",
      Position: "9.5 Cr",
    },
    {
      ProjectName: "Arun Kumar",
      TotalFunds: "89 Lakhs",
      Position: "9.5 Cr",
    },
    {
      ProjectName: "Arun Kumar",
      TotalFunds: "89 Lakhs",
      Position: "9.5 Cr",
    },
    {
      ProjectName: "Arun Kumar",
      TotalFunds: "89 Lakhs",
      Position: "9.5 Cr",
    },
  ];

  // Add more data as needed

  const columns = [
    {
      name: "Project Name",
      selector: (row) => row.ProjectName,
      sortable: true,
      sortFunction: (a, b) => a.localeCompare(b),
      cell: (row, index) => (
        <>
          {index === 0 && (
            <span>
              <BiUpArrow className="text-green-500 text-left inline-block ml-1" />
              You*
            </span>
          )}
          {index !== 0 && <span>{row.ProjectName}</span>}
        </>
      ),
      style: {
        fontWeight: "bold",
      },
    },

    {
      name: "Total Funds",
      selector: (row) => row.TotalFunds,
      cell: (row, index) => (
        <span className={index === 0 ? "text-gray-800" : "text-gray-400"}>
          {row.TotalFunds}
        </span>
      ),
    },
    {
      name: "Position",
      selector: (row) => row.FullyFunded,
      cell: (row, index) => (
        <>
          {index === 0 ? (
            <>
              <GiTrophyCup className="size-8 text-yellow-400" />
              <span className="ml-2">1st Rank</span>
            </>
          ) : (
            <>
              <span className="text-gray-500">
                {index + 1 === 2 ? "2nd" : `${index + 1}th`} Rank
              </span>
              <span className="ml-2">{row.FullyFunded}</span>
            </>
          )}
        </>
      ),
    },
  ];

  const customStyles = {
    Headers: {
      style: {},
    },
    headRow: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
    rows: {
      style: {
        borderRadius: "10px",
        boxShadow: "0 3px #D3D3D3",
        backgroundColor: "#F0FFF0",
        marginBottom: "10px",
      },
    },
    cells: {
      style: {
        fontWeight: "medium",
      },
    },
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [selectedMonth, setSelectedMonth] = useState("");

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const handleDownload = (item) => {
    // Your download logic for the specified item (e.g., 'Amazon')
    console.log(`Downloading ${item}`);
  };

  return (
    <div className=" bg-gray-100 ">
      <div>
        <div className="lg1:flex  grid lg1:grid-cols-1 gap-5  p-4">
          <div className="bg-white w-full md:w-full lg1:w-64 p-4 rounded-lg">
            {/* Card Title */}
            <h1 className="text-md font-bold mb-4">Total Returns Received </h1>

            {/* Flex with two buttons */}
            <div className="md:flex gap-2 mb-4">
              <button className="bg-gradient-to-b from-[#04e301] to-[#01b401] text-white px-14 md:px-7 font-semibold lg:px-10 xl:px-12 py-2 text-sm md:text-md lg:text-base rounded-md text-left">
                ₹14,032.56
              </button>

              <button className="bg-[#C7FFA5] text-black py-1 rounded-md font-semibold">
                +5.63%
              </button>
            </div>
            <div>
              {/* Another Title */}
              <h2 className="text-md font-bold mb-4">Total Upcoming Returns</h2>
              <button className="bg-black text-white w-full px-2 py-2 rounded-md">
                <div className="flex  justify-between  items-center">
                  <div className="mr-2 ml-3">₹7,532.21</div>
                  <BsArrowRightSquare className="bg-mygreen text-black text-2xl rounded-md" />
                </div>
              </button>
            </div>

            <h1 className="text-xs text-gray-400 font-semibold pt-2 mb-4">
              Next Return Dates
            </h1>
            {/* <div className="flex justify-between p-2">
              {/* Column 1 

              <p className="font-bold text-sm text-black ">Portfolio Change</p>

              <p className="text-black  text-sm p-2">
                % change
                <br />
                <span className="text-xs text-green-500">+17.63%</span>
              </p>
            </div> */}
            <div className="flex justify-between  pl-4 pr-6">
              {/* Column 1 */}
              <p className="text-black  text-sm ">
                Next Date
                <br />
                <span className=" text-xs text-black font-bold">25/12/23</span>
              </p>
              {/* <img
                className="h-6" // Adjusted width
                src={Image1}
                alt=""
              /> */}
            </div>

            <div className="flex justify-center mt-7 text-[#00e500]  items-center text-5xl">
              <BiSolidChevronDownSquare />
            </div>
          </div>

          <div className="bg-white p-4 w-full md:w-full flex-grow lg1:w-96 rounded-lg">
            <h2 className="text-md font-bold">Project Investment</h2>

            <div
              ref={containerRef}
              onScroll={handleScroll}
              style={{ maxHeight: "300px", overflowY: "auto" }}
            >
              {displayedData.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col md:flex-row justify-between bg-mygreen  rounded-lg items-center mt-6 mb-10"
                >
                  <div className="w-full md:w-[30%] text-white bg-black lg:h-28 rounded-lg md:text-center">
                    <p className="pt-5 pb-5 pl-2 pr-2 text-xl font-semibold">
                      {item.name}
                    </p>
                  </div>

                  <div className="flex-1 text-center md:ml-2 md:mr-2 md:mt-0 mt-4">
                    <p className="p-4 text-3xl font-bold">{item.amount}</p>
                  </div>

                  {/* <div className="w-full md:w-1/5 text-center bg-[#C1FFC1] lg:h-28 md:h-auto rounded-lg md:ml-2 md:mt-0 mt-4">
                    <p className="pt-7 pb-5 text-xl font-bold">
                      {item.percentage}
                    </p>
                  </div> */}
                </div>
              ))}
            </div>
            {!showAll && (
              <div
                className="flex justify-center  text-[#00e500] mt-4 items-center text-5xl"
                onClick={handleToggleShowAll}
              >
                <BiSolidChevronDownSquare />
              </div>
            )}

            {showAll && (
              <div
                className="flex justify-center  text-[#00e500] mt-4 items-center text-5xl"
                onClick={handleToggleShowAll}
              >
                <BiSolidChevronDownSquare />
              </div>
            )}
          </div>

          <div className="bg-white w-full md:full  lg:w-64 lg1:p-4 p-4 rounded-lg">
            <div className="border-b-2 border-gray-200">
              {/* Title */}
              <h2 className="text-md font-bold mb-1">
                Investment Tenure Snapshot
              </h2>
              <p className="text-xs font-semibold text-gray-400 pb-2 ">
                Updated once every week
              </p>
            </div>
            <div className="">
              {/* Title */}
              <h2 className="text-md font-bold mb-1 pt-1">
                Commercial Real Estate
              </h2>
              <p className="text-xs font-semibold text-gray-400 ">
                Active deal
              </p>
            </div>
            {/* Flex Container */}
            <div className="flex  justify-between">
              {/* Column 1 */}

              <p className="text-black font-bold text-md p-2">
                Start
                <br />
                <span className="text-xs font-semibold text-gray-400">
                  1st Jan 2023
                </span>
              </p>
              <p className="text-black font-bold text-md p-2">
                End
                <br />
                <span className="text-xs font-semibold text-gray-400">
                  1st Jan 2026
                </span>
              </p>
            </div>

            <div className="relative ">
              <p className="border-t-4 border-mygreen p-1 w-full">
                {/* Progress content goes here */}
              </p>
              <div className="absolute top-0 left-1/3 h-full border-l-2 border-black "></div>
            </div>
            <p className="font-semibold text-sm pl-10 border-b-2 border-gray-200">
              25th May 2024
            </p>

            <div className="mt-2">
              {/* Title */}
              <h2 className="text-md font-bold mb-1 pt-1">Machinery Leasing</h2>
              <p className="text-xs font-semibold text-gray-400 ">
                Active deal
              </p>
            </div>
            {/* Flex Container */}
            <div className="flex justify-between">
              {/* Column 1 */}

              <p className="text-black font-bold text-md p-2">
                Start
                <br />
                <span className="text-xs font-semibold text-gray-400">
                  1st Jan 2023
                </span>
              </p>
              <p className="text-black font-bold text-md p-2">
                End
                <br />
                <span className="text-xs font-semibold text-gray-400">
                  1st Jan 2026
                </span>
              </p>
            </div>

            <div className="relative">
              <p className="border-t-4 border-mygreen p-1 w-full">
                {/* Progress content goes here */}
              </p>
              <div className="absolute top-0 left-3/4 h-full border-l-2 border-black"></div>
            </div>
            <div>
              <p className="font-semibold text-sm pl-40 pb-4">3rd Sep 2025</p>
            </div>
            <div className="border-t border-gray-300 "></div>
            {/* Progress content goes here */}

            {/* Column 1 */}
          </div>
        </div>

        {/* card4 */}

        {/* second section */}
        <div className="lg1:flex  grid md:grid-cols-1   md:gap-5  justify-center  md:ml-auto md:mr-auto lg1:gap-6 p-4">
          <div className="w-[360px] ml-3 md:ml-auto md:w-full   lg1:w-80">
            <div className="bg-[#001D51]  md:ml-auto mr-5 ml-10 md:mr-auto  p-4">
              <div className="bg-gradient-to-r from-[#002F62] to-[#003AA7] rounded-lg p-2">
                <div className="flex justify-between items-center">
                  <h2 className="text-lg font-semibold text-white">
                    Investment Allocation
                  </h2>
                  <div className="flex items-center">
                    <label
                      htmlFor="monthDropdown"
                      className="bg-transport border border-gray-300"
                    ></label>
                    <select
                      id="monthDropdown"
                      onChange={handleMonthChange}
                      value={selectedMonth}
                      className="text-xs md:text-sm bg-transport border border-gray-300"
                    >
                      <option value="" disabled></option>
                      {months.map((month, index) => (
                        <option key={index}>{month}</option>
                      ))}
                    </select>
                    {selectedMonth && <p className="text-xs md:text-sm"></p>}
                  </div>
                </div>
                <div>
                  <img src={Img} alt="Your Alt Text" className="pt-4 w-full" />

                  <p className="text-xs md:text-sm flex justify-center items-center text-white font-bold">
                    Total Funds
                  </p>
                </div>
                <div className="flex justify-between ">
                  {/* Column 1 */}
                  <div className="flex ">
                    <TbPointFilled className="text-[#006ee9] text-xl" />
                    <div className="ml-1">
                      <p className="text-white font-bold text-xs md:text-sm">
                        Real Estate
                        <br />
                        <span className="text-[9px] font-semibold text-[#00dcf5]">
                          55%
                        </span>
                      </p>
                    </div>
                  </div>

                  {/* Column 2 */}
                  <div className="flex ">
                    <TbPointFilled className="text-[#00dcf5] text-xl" />
                    <div className="ml-1 mr-2">
                      <p className="text-white font-bold text-xs md:text-sm">
                        Machinery
                        <br />
                        <span className="text-[9px] font-semibold text-[#00dcf5]">
                          20%
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="border p-2 m-4 rounded-lg">
                  <h1 className="flex text-xs md:text-sm items-center justify-center font-bold text-[#00dcf5]">
                    View all activity
                    <IoIosArrowRoundForward className="ml-4 text-lg" />
                  </h1>
                </div>
              </div>
            </div>
          </div>

          {/* Second Card (Smaller Width) */}
          {/* card5 */}
          {/* <div className="content lg1:w-[25rem] w-[360px] ml-4 md:ml-auto mt-10 md:mt-0 rounded-xl md:w-full p-4 font-bold bg-white">
            <div className="ml-5 md:ml-auto">
              <p className="font-bold text-xl">Rankings</p>
              <p className="text-md md:text-lg text-gray-500 font-medium pt-2">
                Your Investment Compared To Others
              </p>
              <p className="text-gray-500 text-xs">(Updated Every Week)</p>
              <div className="flex  w-full   flex-col justify-center items-center">
                <DataTable
                  fixedHeader
                  fixedHeaderScrollHeight="300px"
                  className="w-full h-full"
                  columns={columns}
                  data={datas}
                  customStyles={customStyles}
                />
              </div>
            </div>
          </div> */}

          {/* card6 */}

          <div className="bg-white w-[360px] ml-5 mr-6 md:ml-auto md:mr-auto  mb-5 md:mb-auto  md:w-full  lg1:w-64 rounded-md ">
            <div className="ml-7 md:ml-auto mr-5 md:mr-auto">
              <h2 className="text-lg font-semibold p-4">Invoice & PPS</h2>
              <h2 className="text-xs font-semibold text-gray-500 pl-4">
                Download Your Invoice & Payment Projection Summary Here
              </h2>

              <div className="flex flex-col bg-white shadow-lg m-3 p-2">
                <p className="flex items-center gap-2 p-4 text-xs">
                  <FaAmazon className="text-blue-900 text-lg" />
                  <div>
                    Amazon.com, Inc.
                    <p className=" text-gray-400 text-medium">AMZN</p>
                  </div>
                  <BsDownload
                    className="ml-auto text-gray-500 align-baseline text-xl font-bold cursor-pointer"
                    onClick={() => handleDownload("Amazon")}
                  />
                </p>

                <hr className="border-gray-200"></hr>

                <p className="flex  text-xs p-4 gap-2 items-center ">
                  <SiCocacola className="text-white text-lg rounded-sm bg-red-600 " />

                  <div>
                    Coca-Cola Co
                    <p className="text-gray-400 text-medium">KO</p>
                  </div>
                  <BsDownload
                    className="ml-auto text-gray-500 align-baseline text-xl font-extrabold  cursor-pointer"
                    onClick={() => handleDownload("Amazon")}
                  />
                </p>
                <hr className="border-gray-200"></hr>

                <p className="flex justify-start text-xs  p-4 gap-2 items-center ">
                  <SiBmw className=" text-sky-500 text-lg w-4 h-4 bg-black rounded-full" />
                  <div>
                    Bayerische Motoren Werke AG
                    <p className=" text-gray-400 text-medium">BMW</p>
                  </div>
                  <BsDownload
                    className="ml-auto text-gray-500 align-baseline font-extrabold text-2xl cursor-pointer"
                    onClick={() => handleDownload("Amazon")}
                  />
                </p>
                <hr className="border-gray-200"></hr>

                <p className="flex justify-start p-4 gap-2  text-xs items-center">
                  <ImWindows className="text-orange-400 text-lg " />
                  <div>
                    Microsoft Corp
                    <p className=" text-gray-400 text-medium">MSFT</p>
                  </div>
                  <BsDownload
                    className="ml-auto text-gray-500 align-baseline font-extrabold text-xl cursor-pointer"
                    onClick={() => handleDownload("Amazon")}
                  />
                </p>
                <hr className="border-gray-200"></hr>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
}
