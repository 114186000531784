import './App.css';
import { Route, Routes } from "react-router-dom";
import Login from "./components/common/Login";
import PrivateRoute from "./components/common/PrivateRoute";
import Homepage from "./components/common/Homepage";
import Dashboard from "./components/menus/dashboard/Dashboard";
import Myportfolio from './components/menus/portfolio/MyPortfolio';
import AllTransactions from "./components/menus/allTransactions/AllTransactions";
import Opportunities from './components/menus/oppertunities/Opportunities';
import Profile from "./components/menus/profile/Profile";
import Kyc from './components/menus/kys/Kyc'
import Documents from "./components/menus/documents/demo";
import ReferEarn from "./components/menus/refer&earn/Referral";
// import Blog from "./components/menus/blog/Blogs";
import Webinar from "./components/menus/webinar/Webinar";
import ActiveLog from "./components/menus/activeLog/ActiveLog";
import Settings from "./components/menus/settings/Settings";
import Support from './components/menus/Support/Support';
import Blogs from './components/menus/blog/Blogs';
import BlogPage from './components/menus/blog/BlogPage';
import InvestNow from './components/menus/oppertunities/InvestNow';
// import Sample from './components/menus/blog/Blogs';
import Mainop from './components/menus/oppertunities/MainOpportunity'

function App() {
  return (
    <div className="h-full w-full bg-white">
    
    <Routes>
      <Route path="/" element={<Login />} /> 
      <Route element={<PrivateRoute/>}>
        <Route path="/homepage" element={<Homepage />}>
        <Route path="/homepage/dashboard" element={<Dashboard />} />
        <Route path="/homepage/myportfolio" element={<Myportfolio/>} />
        <Route path="/homepage/alltransactions" element={<AllTransactions />} />
        <Route path='/homepage/opportunities' element={<Mainop/>}/>
        <Route path='/homepage/opportunities/Learnmore' element={<Opportunities/>}/>
        <Route path='/homepage/opportunities/Learnmore/investnow' element={<InvestNow/>}/>
        <Route path="/homepage/myprofile" element={<Profile />} />
        <Route path="/homepage/kyc" element={<Kyc />} />
        <Route path="/homepage/mydocuments" element={<Documents />} />
        <Route path="/homepage/refer&earn" element={<ReferEarn />} />
        <Route path='/homepage/blog' element={<Blogs/>}/>
        <Route path="/homepage/blog/:id" element={<BlogPage />} />
       <Route path="/homepage/webinar" element={<Webinar />} />
       <Route path="/homepage/notificationCenter" element={<ActiveLog />} />
       <Route path="/homepage/settings" element={<Settings />} /> 
       <Route path="/homepage/support" element={<Support/>} />
        </Route>
        </Route>
    </Routes>
  </div>
  );
}

export default App;
