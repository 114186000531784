import { Button, Input, Tabs, Table } from "antd";
import React, { useState } from "react";
import refer2 from "../../../assets/refer2.png";

const { TabPane } = Tabs;

const Referral = () => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };
  const dataReferralsList = [
    {
      key: "1",
      name: "John Doe",
      email: "john@example.com",
      amountInvested: "₹5000",
    },
    {
      key: "2",
      name: "Jane Smith",
      email: "jane@example.com",
      amountInvested: "₹3000",
    },
  ];

  const dataNotInvested = [
    {
      key: "1",
      name: "Alice Johnson",
      email: "alice@example.com",
      amountInvested: "₹0",
    },
    {
      key: "2",
      name: "Bob Brown",
      email: "bob@example.com",
      amountInvested: "₹0",
    },
  ];

  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      width: 50,
      render: (text, record, index) => (
        <span className="text-blue-500">
          {(pagination.current - 1) * pagination.pageSize + index + 1}
        </span>
      ),
    },
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Project Name",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Invested Date",
      dataIndex: "amountInvested",
      key: "amountInvested",
    },
    {
      title: "Invested Amount",
      dataIndex: "amountInvested",
      key: "amountInvested",
    },
    {
      title: "Earned Amount",
      dataIndex: "amountInvested",
      key: "amountInvested",
    },
  ];
  const columns1 = [
    {
      title: "S.No",
      dataIndex: "index",
      width: 50,
      render: (text, record, index) => (
        <span className="text-blue-500">
          {(pagination.current - 1) * pagination.pageSize + index + 1}
        </span>
      ),
    },
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Registration Date",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "amountInvested",
      key: "amountInvested",
    },
    {
      title: "Eligible UpTo",
      dataIndex: "amountInvested",
      key: "amountInvested",
    },
    {
      title: "Earned Amount",
      dataIndex: "amountInvested",
      key: "amountInvested",
    },
  ];

  return (
    <div className="flex flex-col items-center bg-gray-50">
      <div className=" flex justify-center items-center mt-4 bg-white rounded-xl w-full h-20 ">
        <h1 className="text-[#2272FF] text-2xl font-bold text-center">
          REFER & EARN
        </h1>
      </div>    

      <div className=" flex flex-col lg:flex-row items-center justify-center py-7 px-5 gap-10">
        <div className="flex-1 flex justify-center items-center mb-4 lg:mb-0">
          <img
            src={refer2}
            alt="Referral"
            className="object-cover h-48 max-w-xs lg:h-1/4 lg:max-w-lg"
          />
        </div>

        <div className="flex-1 max-w-xs w-full bg-white rounded-lg shadow-lg p-4 lg:max-w-lg lg:w-full lg:mr-10">
          {" "}
          {/* Adjust max-w here */}
          <div className="text-center mb-4 mt-4">
            <p className="text-2xl font-semibold text-gray-600">
              Invite Your Friends And Earn Money
            </p>
          </div>
          <hr />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 mt-2 text-center">
            <div>
              <p className="text-lg font-medium text-[#2272FF]">
                Earned Amount
              </p>
              <p className="text-xl font-semibold text-gray-800">₹ 0</p>
            </div>
            <div>
              <p className="text-lg font-medium text-[#2272FF]">
                Available Amount
              </p>
              <p className="text-xl font-semibold text-gray-800">₹ 0</p>
            </div>
          </div>
          <div className="mb-4">
            <p className="text-md font-medium text-[#2272FF] mb-2">
              Enter Your Amount
            </p>
            <div className="relative flex flex-col md:flex-row">
              <input
                className="flex-1 border border-gray-300 rounded-lg p-2 pr-4 h-10 mb-2 md:mb-0 md:mr-2 outline-none"
                style={{ width: "100%" }}
                placeholder="Enter the amount"
              />
              <button
                className="h-10 bg-[#2272FF] text-white rounded-lg px-4 hover:bg-[#004B8A] transition-all"
                style={{ width: "100px" }}
              >
                Request
              </button>
            </div>
          </div>
          <div className="mb-4 text-center">
            <p className="text-md text-gray-600">
              You can earn ₹10,000 and more!
            </p>
          </div>
          <div className="mb-4">
            <p className="text-sm text-gray-500 text-center">
              To transfer your referral earnings from your Vault to your Bank
              account, submit a withdrawal request.
            </p>
          </div>
          <div className="mb-4">
            <p className="text-md font-medium text-[#0054A6] mb-2">
              Invite a Friend
            </p>
            <div className="flex flex-col md:flex-row">
              <input
                className="border border-gray-300 rounded-lg p-2 h-10 mb-2 md:mb-0 md:mr-2 flex-1 outline-none"
                placeholder="Enter friend's email address"
              />
              <button
                className="h-10 bg-[#2272FF] text-white rounded-lg px-4 hover:bg-[#004B8A] transition-all"
                style={{ width: "100px" }}
              >
                Invite
              </button>
            </div>
          </div>
          <div className="mb-2">
            <p className="font-medium text-[#0054A6] text-md">
              Share your referral code
            </p>
          </div>
          <div className="relative mb-4">
            <input
              className="w-full border border-gray-300 rounded-lg p-2 h-10 pr-10 outline-none"
              placeholder="Referral Code"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 cursor-pointer"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8 16h12M8 12h12m-7 8H5V8m4-4H5v12m16-6v10a2 2 0 01-2 2H9a2 2 0 01-2-2V6a2 2 0 012-2h6a2 2 0 012 2v4z"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className=" flex justify-center items-center bg-white rounded-xl w-full h-20 ">
        <h1 className="text-[#2272FF] text-2xl font-bold text-center">
          Your Referrals
        </h1>
      </div>
      {/* Tabs Section */}
      <div className="py-5 w-full px-5">
      <div className="w-full bg-white shadow-lg p-4 mt-10 ">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Referrals List" key="1">
            <Table
              columns={columns}
              dataSource={dataReferralsList}
              pagination={false}
              onChange={handleTableChange}
              rowClassName={(record, index) =>
                index % 2 === 0 ? "bg-gray-50" : "bg-white"
              }
              className="border rounded-lg"
            />
          </TabPane>
          <TabPane tab="Referrals not yet Invested" key="2">
            <Table
              columns={columns1}
              dataSource={dataNotInvested}
              pagination={false}
              onChange={handleTableChange}
              rowClassName={(record, index) =>
                index % 2 === 0 ? "bg-gray-50" : "bg-white"
              }
              className="border rounded-lg"
            />
          </TabPane>
        </Tabs>
      </div>
      </div>
    </div>
  );
};

export default Referral;